<template>
  <v-data-table v-bind="$attrs" :options.sync="computedOptions">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </v-data-table>
</template>

<script type="text/javascript">
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    computedOptions: {
      get() {
        return this.options;
      },
      set(value) {
        this.$emit('options-changed', value);
      }
    }
  }
};
</script>

<style scoped>
.v-data-table >>> thead th {
  color: var(--v-black-base) !important;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
  padding: 15px 20px !important;
  border-bottom: none !important;
}
.v-data-table >>> tbody tr {
  border-bottom: none !important;
}
.v-data-table >>> tbody td {
  font-size: 1rem !important;
  padding: 15px 20px !important;
}
.v-data-table--mobile >>> tbody {
  display: contents !important;
}
</style>

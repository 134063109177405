export const LAYOUT_NAMESPACE = 'layout';

export const CHECK_MAINTENANCE_MODE = 'CHECK_MAINTENANCE_MODE';

export default (Vue) => ({
  namespaced: true,
  state: {
    miniSidebar: true,
    showMenu: false,
    menuLocked: false,
    showToolbarTitle: false,
    globalSnackbar: {
      text: '',
      color: '',
      icon: '',
      visible: false
    },
    globalSearchActive: false,
    globalSearchTerm: '',
    maintenanceMode: false
  },
  mutations: {
    toggleSidebar(state) {
      state.miniSidebar = !state.miniSidebar;
    },
    setSidebar(state, miniSidebar) {
      state.miniSidebar = miniSidebar;
    },
    toggleMenu(state) {
      state.showMenu = !state.showMenu;
    },
    setMenu(state, value) {
      state.showMenu = value;
    },
    lockMenu(state) {
      state.menuLocked = true;
    },
    unlockMenu(state) {
      state.menuLocked = false;
    },
    setToolbarTitle(state, value) {
      state.showToolbarTitle = value;
    },
    showGlobalSnackbar(state, globalSnackbar) {
      state.globalSnackbar.text = globalSnackbar.text;
      state.globalSnackbar.color = globalSnackbar.color;
      state.globalSnackbar.icon = globalSnackbar.icon;
      state.globalSnackbar.visible = true;

      setTimeout(() => (state.globalSnackbar.visible = false), 5000);
    },
    toggleGlobalSearch(state) {
      state.globalSearchTerm = '';
      state.globalSearchActive = !state.globalSearchActive;
    },
    setGlobalSearchTerm(state, value) {
      state.globalSearchTerm = value;
    },
    setMaintenanceMode(state, value) {
      state.maintenanceMode = value;
    }
  },
  actions: {
    async [CHECK_MAINTENANCE_MODE]({ commit }) {
      if (Vue.prototype.$auth.check('ROLE_SUPER_ADMINISTRATOR')) {
        commit('setMaintenanceMode', false);
        return;
      }

      Vue.prototype.$http
        .get('/maintenance-mode')
        .then((response) => {
          commit('setMaintenanceMode', false !== response?.data?.status);
        })
        .catch(() => {
          commit('setMaintenanceMode', true);
        });
    }
  }
});

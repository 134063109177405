export const NO_INFORMATION = 'Keine Angabe';

export default {
  layout: {
    capture: 'Erfassung',
    captureList: 'Erfassungsliste',
    archive: 'Archiv',
    user: 'Nutzerverwaltung',
    baseData: 'Stammdaten',
    import: 'Import',
    report: 'Report',
    analysis: 'Analyse',
    logout: 'Logout'
  },
  common: {
    date: 'Datum',
    time: 'Zeit',
    yes: 'Ja',
    no: 'Nein',
    ok: 'Ok',
    save: 'Speichern',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    line: 'Linie',
    branch: 'Betreiber',
    place: 'Ort',
    stop: 'Haltepunkt',
    kind: 'Ereignisart',
    type: 'Ereignistyp',
    perpetrator: 'Täter',
    affectedPeople: 'Betroffene',
    fetchError: 'Fehler beim Abruf der Daten vom Server',
    postError: 'Fehler beim Senden der Daten zum Server'
  },
  headline: {
    incidentLocationType: 'Erfassungsort',
    direction: 'Richtung',
    policeInAttendance: 'Polizei vor Ort'
  },
  label: {
    incident: {
      kind: 'Ereignisarten',
      perpetrator: 'Täter',
      type: 'Ereignistypem',
      affectedPeople: 'Betroffene'
    }
  },
  incident: {
    incidentLocationType: {
      line: 'Linie',
      stop: 'Haltepunkt'
    },
    kind: {
      abuse_of_emergency_brake: {
        title: 'Missbrauch Notbremse',
        description: 'Missbräuchliches Betätigen einer Notbremse oder des Notrufes (§ 145 StGB)'
      },
      assault: {
        title: 'Körperverletzung',
        description:
          'Eingriff in die körperliche Unversehrtheit einer Person in Form einer körperlichen Misshandlung, einer Gesundheitsschädigung oder einer Körperverletzung (§ 223 StGB)'
      },
      criminal_mischief: {
        title: 'Sachbeschädigung',
        description:
          'Rechtswidrige Beschädigung und/oder Zerstörung sowie erhebliche und nicht vorübergehende Veränderung einer fremden Sache (§ 303 StGB)'
      },
      heist: {
        title: 'Raub',
        description:
          'Wegnehmen einer fremden beweglichen Sache mit Gewalt gegen eine Person oder unter Anwendung von Drohungen mit gegenwärtiger Gefahr für Leib oder Leben in der Absicht, die Sache sich oder einem Dritten rechtswidrig zuzueignen (§ 249 StGB)'
      },
      insult: {
        title: 'Beleidigung',
        description:
          'Nicht- oder Missachtung gegenüber anderen Personen, ehrverletzende Werturteile, herabwürdigende, missachtende Tatsachenbehauptungen (§ 185 StGB)'
      },
      sex_offense: {
        title: 'Sexualdelikt',
        description:
          'Verstöße gegen die sexuelle Selbstbestimmung; sexistische, geschlechtsbezogene entwürdigende bzw. beschämende Bemerkungen und Handlungen, unerwünschte körperliche Annäherung und Annäherungen in Verbindung mit Versprechen von Belohnungen und/oder Androhung von Repressalien (§ 177 StGB, § 184i StGB)'
      },
      subreption_of_services: {
        title: 'Erschleichen von Leistungen',
        description:
          'Erschleichen der Beförderung durch ein Verkehrsmittel in der Absicht, das Entgelt nicht zu entrichten (§ 265a StGB)'
      },
      theft: {
        title: 'Diebstahl',
        description:
          'Wegnahme einer fremden beweglichen Sache in der Absicht, sich diese rechtswidrig zuzueignen (§ 242 StGB)'
      },
      threat: {
        title: 'Bedrohung',
        description: 'Ernstliche Androhung eines Verbrechens, Umsetzbarkeit der Drohung ist unerheblich (§ 241 StGB)'
      },
      trespass: {
        title: 'Hausfriedensbruch',
        description:
          'Widerrechtliches Eindringen und/oder Verweilen ohne Befugnis trotz Aufforderung zum Verlassen des Ortes (§ 123 StGB)'
      },
      aggressive_begging: {
        title: 'Aggressives Betteln',
        description:
          'Aufdringliches, hartnäckiges, beleidigendes Ansprechen, Anfassen, Einreden, Verfolgen, Festhalten, in den Weg stellen bis hin zu Handgreiflichkeiten, um eine Gabe quasi zu "erpressen" (§ 118 OWiG)'
      },
      trouble: {
        title: 'Belästigung',
        description:
          'Handlung, die die öffentliche Ordnung unmittelbar stört oder beeinträchtigt und die Öffentlichkeit belästigt (§ 118 OWiG)'
      },
      contamination: {
        title: 'Verunreinigung',
        description:
          'Vorsätzliche Verunreinigung einer Bahnanlage, einer Betriebseinrichtung oder eines Fahrzeugs (EBO § 64b)'
      },
      persons_without_travel_intention_present: {
        title: 'Personen ohne Reiseabsicht anwesend',
        description:
          'Aufenthalt von Personen ohne Fahrschein in Bereichen, in denen ein gültiger Fahrschein Pflicht ist (nur bei Ereignissen in Stationen)'
      },
      alcohol_drug_users_present: {
        title: 'Alkohol- / Drogenkonsumenten anwesend',
        description:
          'Alkohol und/oder Drogen konsumierende bzw. konsumiert habende Personen, die durch ihr allgemeines Erscheinungsbild und Auftreten die subjektive Sicherheit der Fahrgäste stören'
      },
      mandatory_mask_disregarded: {
        title: 'Nichteinhaltung Maskenpflicht',
        description:
          'Verpflichtung zum Tragen einer Mund-Nase-Bedeckung bei der Nutzung von Beförderungsleistungen des Personenverkehrs (§2 Coronaschutzverordnung, seit 27.04.2020)'
      },
      violation_3g_rules: {
        title: 'Verstoß gegen 3G Regeln'
      },
      abuse_emergency_call_device: {
        title: 'Missbrauch Notrufeinrichtung',
        description: 'Missbräuchliches Betätigen der Notrufeinrichtungen (§ 145 StGB)'
      },
      unassignable_object: {
        title: 'NZG - Nicht zuzuordnender Gegenstand',
        description:
          'Ein NZG ist ein nicht zuzuordnender Gegenstand (Koffer, Tasche etc.), der nicht eindeutig einem bestimmten Besitzer zugewiesen werden kann sowie deren Inhalt nicht zu bestimmen ist und folglich nicht als gefahrlos eingestuft werden kann. In diesem Fall ist die Polizei ausnahmslos zu rufen.'
      }
    },
    type: {
      no_information: {
        title: NO_INFORMATION,
        description:
          'Vorsätzliche Beschädigung und Zerstörung fremden Eigentums in Zügen und an Stationen, die keinem der gelisteten Ereignistypen zugeordnet werden können'
      },
      attach_on_vehicles: {
        title: 'Angriff auf Fahrzeuge',
        description: 'Gewaltanwendungen gegen Fahrzeuge, z. B. Steinwurf, Beschuss o. ä.'
      },
      breaking_up_machines: {
        title: 'Aufbrechen von Automaten',
        description:
          'Aufbrechen von Fahrkartenautomaten oder sonstigen Automaten auf Bahnsteigplatten oder in Fahrzeugen'
      },
      damage_to_seats: {
        title: 'Beschädigung von Sitzen',
        description: 'Zufügen von Schäden an Sitzen in Fahrzeugen oder an Haltestellen'
      },
      burglary: {
        title: 'Einbruch',
        description: 'Unerlaubtes, meist gewaltsames Eindringen in Betriebsräume oder Betriebsgebäude'
      },
      etching: {
        title: 'Etching',
        description: 'Verätzen durch Flusssäure'
      },
      graffiti: {
        title: 'Graffiti',
        description: 'Beschmieren oder Besprühen von Betriebseigentum mit Farbe'
      },
      scratching: {
        title: 'Scratching',
        description: 'Zerkratzen von Glas oder anderen Einrichtungsgegenständen'
      },
      with_the_use_of_weapons: {
        title: 'Mit Waffengebrauch',
        description: 'Während des Vorfalls sind eine oder mehrere Waffen eingesetzt worden.'
      }
    },
    perpetrator: {
      no_information: {
        title: NO_INFORMATION,
        description: 'Personen, die nicht eindeutig einer anderen Gruppierung zugeordnet werden können'
      },
      alcohol_drug_users: {
        title: 'Alkohol- / Drogenkonsumenten',
        description: 'Personen, die aufgrund ihres Verhaltens offensichtlich Alkohol und/oder Drogen konsumiert haben'
      },
      soccer_fans: {
        title: 'Fußballfans',
        description:
          'Personen, die aufgrund ihrer Kleidung und/oder ihres Verhaltens als Fußballfans identifizierbar sind'
      },
      groups: {
        title: 'Gruppen',
        description: 'Mehr als zwei Personen'
      },
      musicians: {
        title: 'Musikanten',
        description: 'Personen, die im ÖPNV (Fahrzeuge und/oder Haltepunkte) musizieren und/oder singen'
      },
      persons_without_travel_intention: {
        title: 'Personen ohne Reiseabsicht',
        description:
          'Personen, die sich in Bereichen aufhalten, in denen ein gültiger Fahrschein Pflicht ist und diesen nicht vorweisen können'
      }
    },
    affectedPeople: {
      not_present: {
        title: NO_INFORMATION
      },
      passengers: {
        title: 'Fahrgäste',
        description: 'Personen, die sich in Fahrzeugen und/oder an Haltepunkten aufhalten'
      },
      police: {
        title: 'Polizei',
        description: 'Personen, die aufgrund ihrer Funktion und Uniform der Bundes- oder Landespolizei zuzuordnen sind'
      },
      security_personnel: {
        title: 'Verkehrsunternehmen',
        description:
          'Wenn bei Linienvorfällen keine Personen betroffen sind (z. B. Erschleichen von Leistungen, Sachbeschädigung, etc.)'
      },
      train_crew: {
        title: 'Zugpersonal',
        description:
          'Personen, die in den Fahrzeugen ihren Dienst verrichten (z. B. Triebfahrzeugführer, Kundenbetreuer, etc.)'
      },
      station_personnel: {
        title: 'Stationspersonal',
        description:
          'Personen, die in den Verkehrsstationen ihren Dienst verrichten (z. B. Servicekräfte, Reinigungskraft, MA DB Sicherheit, etc.)'
      },
      traffic_station: {
        title: 'Verkehrsstation',
        description:
          'Wenn bei Stationsvorfällen keine Personen betroffen sind und die Verkehrsstation durch das Ereignis zu Schaden kommt'
      },
      back_and_call_service: {
        title: 'Sicherheitsteam',
        description:
          'Sicherheitspersonal in 2er-Teams mit Bodycams, das NRW-weit und unternehmensunabhängig in SPNV-Fahrzeugen eingesetzt wird; Aufdruck Dienstkleidung: „Präsent, sicher, stark“'
      },
      additional_security_personnel: {
        title: 'Zusätzliches Sicherheitspersonal',
        description: 'Sicherheitspersonal verschiedener Dienstleister, das EVU und EIU gebunden im Einsatz ist.'
      }
    },
    policeInAttendance: {
      no_information: 'Polizei nicht gerufen',
      yes: 'Polizei gerufen und erschienen',
      no: 'Polizei gerufen aber nicht erschienen'
    }
  }
};

<template>
  <v-app>
    <desktop v-if="$vuetify.breakpoint.smAndUp">
      <password-change v-if="passwordChangeRequired()" />
      <template v-else>
        <v-overlay :value="loading" data-cy="loadingOverlay">
          <v-card class="mx-4 pt-2" light width="450">
            <v-card-text class="text-center">
              <v-progress-circular :size="100" :width="3" color="primary" indeterminate />
              <v-card-text>{{ $t('loadingPleaseWait') }}</v-card-text>
            </v-card-text>
          </v-card>
        </v-overlay>
        <router-view />
      </template>
    </desktop>
    <mobile v-else>
      <password-change v-if="passwordChangeRequired()" />
      <template v-else>
        <v-overlay :value="loading" data-cy="loadingOverlay">
          <v-card class="mx-4 pt-2" light>
            <v-card-text class="text-center">
              <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
              <v-card-text>{{ $t('loadingPleaseWait') }}</v-card-text>
            </v-card-text>
          </v-card>
        </v-overlay>
        <router-view />
      </template>
    </mobile>
    <mobile-landscape-lock></mobile-landscape-lock>
  </v-app>
</template>

<script>
import PasswordChange from '@/shared/modules/Account/views/PasswordChange';
import Desktop from '@/shared/modules/Layout/views/Desktop';
import Mobile from '@/shared/modules/Layout/views/Mobile';
import MobileLandscapeLock from '@/shared/modules/Layout/components/MobileLandscapeLock';
import { authUser } from '@/shared/helper/vue-auth';
import { mapActions } from 'vuex';
import { FETCH_UNREAD_COUNT, NEWS_NAMESPACE } from '@/shared/modules/News/store';
import UnavailableMixin from '@/pwa/modules/Layout/components/UnavailableMixin.vue';

export default {
  mixins: [UnavailableMixin],
  components: {
    PasswordChange,
    Desktop,
    Mobile,
    MobileLandscapeLock
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMobileLandscape: false
    };
  },
  methods: {
    ...mapActions(NEWS_NAMESPACE, { fetchUnreadCount: FETCH_UNREAD_COUNT }),
    determineMobileLandscape() {
      this.isMobileLandscape = window.innerWidth < 1024 && window.innerWidth > window.innerHeight;
    },
    passwordChangeRequired() {
      return authUser(this.$auth, 'passwordChangeRequired');
    }
  },
  mounted() {
    const self = this;
    window.addEventListener('resize', function () {
      self.determineMobileLandscape();
    });
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        if (false === this.isUnavailable && true === this.$auth.check()) {
          await this.fetchUnreadCount();
        }
      }
    }
  }
};
</script>

<style src="@/shared/css/fonts.css"></style>
<style src="@/shared/css/global-styles.css"></style>

<template>
  <v-btn class="elevation-0 gradiant-button" v-on="$listeners" v-bind="$attrs">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'GradiantButton',
  inheritAttrs: false
};
</script>

<style scoped>
.gradiant-button {
  background: linear-gradient(to right, #009ee0 0%, #00cdf2 100%);
  border-radius: 18px;
  color: var(--v-white-base);
}
</style>

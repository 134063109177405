<template>
  <v-navigation-drawer app clipped permanent :mini-variant="miniSidebar" :width="230" :mini-variant-width="80">
    <v-list class="pt-12" data-cy="navigationList">
      <div v-for="entry in menuEntries" :key="entry.index">
        <v-list-item
          v-if="hasAccess(entry.routeName)"
          :to="entry.routeName ? { name: entry.routeName } : null"
          :disabled="menuLocked"
          :data-cy="'navigationItem-' + entry.title.replace(/\s/, '')"
        >
          <v-list-item-action>
            <v-icon>{{ entry.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ entry.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>

    <v-list class="logout mt-12">
      <v-list-item class="text-uppercase" @click="doLogout" data-cy="logout">
        <v-list-item-action>
          <v-icon color="secondary">fas fa-sign-out-alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('layout.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list>
      <v-list-item class="pointer" @click="toggleSidebar">
        <v-list-item-action>
          <v-icon v-if="miniSidebar">fal fa-chevron-double-right</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="text-right">
            <v-icon color="grey">fal fa-chevron-double-left</v-icon>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { LAYOUT_NAMESPACE } from '@/shared/modules/Layout/store';

export default {
  data() {
    return {
      menuEntries: [
        {
          routeName: 'capture',
          icon: 'fas fa-file-edit',
          title: this.$t('layout.capture')
        },
        {
          routeName: 'archive',
          icon: 'fas fa-archive',
          title: this.$t('layout.archive')
        },
        {
          routeName: 'user-management',
          icon: 'fas fa-user-shield',
          title: this.$t('layout.user')
        },
        {
          routeName: 'base-data',
          icon: 'fas fa-folder-open',
          title: this.$t('layout.baseData')
        },
        {
          routeName: 'import',
          icon: 'fas fa-file-upload',
          title: this.$t('layout.import')
        },
        {
          routeName: 'reporting',
          icon: 'fas fa-file-alt',
          title: this.$t('layout.report')
        },
        ...(process.env.VUE_APP_ANALYTICS === 'active'
          ? [
              {
                routeName: 'analysis',
                icon: 'fas fa-file-chart-line',
                title: this.$t('layout.analysis')
              }
            ]
          : [])
      ]
    };
  },
  computed: mapState(LAYOUT_NAMESPACE, ['miniSidebar', 'menuLocked']),
  methods: {
    hasAccess(routeName) {
      const route = this.$router.options.routes[1].children.find((route) => route.name === routeName);

      if (!route.meta) {
        return false;
      }

      if (Array.isArray(route.meta.auth)) {
        return this.$auth.check(route.meta.auth);
      }

      return route.meta.auth.roles ? this.$auth.check(route.meta.auth.roles) : false;
    },
    doLogout() {
      this.$auth.logout({
        redirect: {
          name: 'login'
        }
      });
    },
    ...mapMutations(LAYOUT_NAMESPACE, ['toggleSidebar', 'setSidebar'])
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  display: flex;
  flex-direction: column;
}
.v-list {
  padding: 0;
}
.v-list-item {
  color: var(--v-grey-base);
  height: 64px;
}
.v-list-item--active {
  border-right: 3px solid var(--v-primary-base);
}
.v-list-item--active.v-list-item--disabled {
  border-right: none;
}
.v-list-item--active:before {
  background-color: transparent;
}
.v-list-item__action {
  justify-content: center;
  min-width: 48px;
  margin-right: 15px;
}
.v-list-item__action .v-icon {
  color: var(--v-grey-base);
}
.v-list-item__content {
  font-size: 16px;
}
.v-list-item__content .v-list-item__title {
  color: var(--v-grey-base);
}
.v-list-item--active .v-list-item__title,
.v-list-item--active .v-icon {
  color: var(--v-primary-base);
}
.v-list-item--active.v-list-item--disabled .v-list-item__title,
.v-list-item--active.v-list-item--disabled .v-icon {
  color: var(--v-grey-base);
}
.logout {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
